import request from "../utils/request";

// 分页获取邀创建的邀请码
export function queryInvitationPage(params) {
    return request({
        url: "/vc-invitation-code",
        method: "GET",
        params
    })
}

// 添加邀请码
export function addInvitation(params) {
    return request({
        url: "/vc-invitation-code",
        method: "POST",
        data: params
    })
}

// 删除未使用的邀请码
export function delInvitation(id) {
    return request({
        url: "/vc-invitation-code/" + id,
        method: "DELETE"
    })
}