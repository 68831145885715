<template>
  <div>
    <!--工具栏-->
    <el-row>
      <el-col :span="24">
        <div class="tools">
          <el-button @click="handleAdd" size="mini" icon="el-icon-plus" type="primary">新增邀请码</el-button>
          <el-button :loading="false" :disabled="false" size="mini" icon="el-icon-refresh" @click="refresh()">刷新</el-button>
        </div>
      </el-col>
    </el-row>
    <!--表格-->
    <el-row>
      <el-col :span="24">
        <div style="width: auto;">
          <el-table
              :data="tableData"
              stripe
              style="width: 100%;">
            <el-table-column
                type="selection"
                align="center"
                width="auto">
            </el-table-column>
            <el-table-column
                prop="id"
                label="邀请码编号"
                width="auto">
            </el-table-column>
            <el-table-column
                prop="invitationCode"
                label="邀请码"
                width="auto">
            </el-table-column>
              <el-table-column
                      prop="validDay"
                      label="有效时长（天）"
                      width="auto">
              </el-table-column>
                <el-table-column
                  prop="availableCount"
                  label="可用次数"
                  width="auto">
              </el-table-column>
            <el-table-column
                prop="isBand"
                label="是否已被使用">
                <template slot-scope="scope">
                    <span v-if="scope.row.isBand == 0">未使用</span>
                    <span v-if="scope.row.isBand == 1">已使用</span>
                </template>
            </el-table-column>
              <el-table-column
                      prop="remark"
                      label="备注">
              </el-table-column>
              <el-table-column
                      prop="createTime"
                      label="创建时间">
              </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="text"

                    icon="el-icon-delete"
                    @click="handleDelete(scope.$index, scope.row)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 15px;text-align: right;">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :page-sizes="[10,20]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog
        title="新增"
        :visible.sync="addDialogVisible"
        width="55%">
      <el-form :inline="true" :model="addInvitationInfo">
        <el-form-item class="text" label="请输入当前激活码有效天数（输入0代表永久有效）">
          <el-input-number
              placeholder="有效天数"
              v-model="addInvitationInfo.validDay"
              size="small"
              clearable>
          </el-input-number>
        </el-form-item>

        <el-form-item class="text" label="请输入当前激活码每天可用次数">
          <el-input-number
              placeholder="可用次数"
              v-model="addInvitationInfo.availableCount"
              size="small"
              clearable>
          </el-input-number>
        </el-form-item>
        <el-form-item class="text" label="备注">
            <el-input
              placeholder="备注"
              v-model="addInvitationInfo.remark"
              size="small"
              clearable>
            </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="addDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addInvitationCode()">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import { queryInvitationPage } from '@/api/invitation.js'
import { addInvitation } from '@/api/invitation.js'
import { delInvitation } from '@/api/invitation.js'

export default {
  name: "Home",
  data() {
    return {
      addInvitationInfo: {
          validDay: "",
          availableCount: "",
          remark: ""
      },
      tableData: [],
      queryInfo: {
        //当前的页数
        page: 1,
        //当前每页显示的多少条数据
        pageSize: 10
      },
      total: 0,
      addDialogVisible: false,
      editDialogVisible: false
    }
  },
  created () {
      this.refresh();
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    onReset() {
      this.refresh();
    },
    handleAdd(){
      this.addDialogVisible=true;
    },
    refresh() {
      queryInvitationPage(this.queryInfo).then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
          this.queryInfo.page = res.data.current
          this.queryInfo.pageSize = res.data.size
      })
    },
    addInvitationCode() {
        addInvitation(this.addInvitationInfo).then((res) => {
            if (res.code === 0) {
              this.$message.success("添加成功")
                // 添加成功
                this.refresh();
            } else {
                // 添加失败
                this.$message.error(res.msg)
            }
        })
        this.addDialogVisible = false
        this.addInvitationInfo.validDay = null
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.editDialogVisible=true;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('是否确认删除邀请码为"' + row.invitationCode + '"的数据项??', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        delInvitation(row.id).then((res) => {
          if (res.code === 0) {
            this.$message.success("删除成功!")
            this.refresh();
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.refresh();
    },
    handleCurrentChange(val) {
      this.queryInfo.page = val
      this.refresh();
    },
  }
}
</script>
<!--公共样式-->
<style scoped lang="scss">

</style>
